.outer_milestone_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8%;
}
.milestone_container {
  position: relative;
  width: 100%;
  /* display : grid;
  grid-template-columns : repeat(4,1fr);
  column-gap: 1.5rem;
  row-gap: 10em; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* padding : 0 2%;   */
  margin-bottom: -5%;
  z-index: 20;
}

.milestone_container .card {
  position: relative;
  width: 260px;
  height: 210px;
  background-color: #414a4c;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 20px rgba(189, 178, 178, 0.5);
  transition: 0.3s ease-in-out;
  border-radius: 15px;
  margin: 6% 2%;
  /* margin-left: 10%; */
}
.milestone_container .card:hover {
  transform: translateY(-10px);
  box-shadow: 0 5px 20px rgba(244, 227, 227, 0.2);
  transition-delay: 0.2s;
  margin-bottom: -50px;
  z-index: 20;
}
.milestone_container .card:hover {
  height: 300px;
  width: 280px;
  padding-left: 30px;
}

.milestone_container .card .image {
  position: relative;
  width: 260px;
  height: 260px;
  bottom: 40%;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.milestone_container .card:hover .image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: auto;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  transition-delay: 0.1s;
}
.milestone_container .card .image img {
  min-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

/* .milestone_container .card:hover .image img {
  position: relative;
  left: 5%;
  padding: auto;
  transition-delay: 0.1s;
} */

.milestone_container .card .content {
  position: relative;
  top: -140px;
  padding: 10px 15px;
  color: #c6e9f8;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.milestone_container .card:hover .content {
  margin-top: 35px;
  visibility: visible;
  opacity: 1;
  transition-delay: 0.2s;
  height: auto;
  width: auto;
}

.milestone_container .card .content h3 {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #0095da;
}

@media screen and (max-width: 750px) {
  .outer_milestone_container {
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    justify-content: center;
    margin-top: 30%;
  }
  .milestone_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: auto;
    /* width: 20%; */
  }
  .milestone_container .card {
    margin: 15% 0px;
  }
  .milestone_container .card .image {
    bottom: 32%;
  }
}
