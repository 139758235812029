.banner {
  width: 100vw;
  position: relative;
  margin-top: 10rem;
  background-size: cover;
  z-index: 1;
  padding-top: 100px;
  padding-bottom: 50px;
  max-width: 70%; /* Adjusts the width to be 70% of the container */
  max-height: 50%; /* Adjusts the height to be 70% of the container */
  object-fit: contain; /* Maintains the aspect ratio of the images */
  margin: 0 auto; /* Centers the image within its container */
}
.banner-div {
  position: relative; /* Keeps the container positioning context */
  overflow: hidden; /* Ensures images don't overflow the container */
  background-color: #b8c0e7 !important; /* Add a background color around the carousel */
  padding: 20px; /* Adds space around the carousel */
}

.overlay {
  position: relative;
  width: 100%;
  top: 0;
  bottom: 0;
  opacity: 0;
  left: 0;
  right: 0;
  transform: scale(0.5);
  animation: 0.5s slideIn ease-in-out forwards, 0.8s skewBg ease-in-out;
}

.welcome_title {
  position: absolute;
  top: 30%;
  left: 38%;
  font-size: 4rem;
  max-width: 40ch;
  /* text-align: center; */
  transform: scale(0.94);
  z-index: 5;
  animation: scale 2s forwards cubic-bezier(0.5, 1, 0.89, 1);
}

.welcome_title span {
  display: inline-block;
  opacity: 0;
  filter: blur(4px);
  color: #b8c0e7;
}

.welcome_title span:nth-child(1) {
  animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.club_name {
  min-height: 100vh;
  position: absolute;
  width: 100vw;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  z-index: 5;
  overflow: hidden;
  text-align: center;
  top: 40%;
  opacity: 0;
  mix-blend-mode: screen;
  transform: translateY(500px);
  animation: 2s slideIn ease-in-out forwards 1s;
}

.animate_character {
  background-image: linear-gradient(
    -225deg,
    #322976 0%,
    #5299dfdd 29%,
    #1b9ef5 67%,
    #8686f2 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 8rem;
  color: #2085de;
}
@keyframes scale {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}
@keyframes skewBg {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slideIn {
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.explore_btn_div {
  position: absolute;
  bottom: 10%;
  width: 100%;
  text-align: center;
  z-index: 20;
}
.explore_btn {
  width: 200px;
  padding: 15px 0;
  text-align: center;
  margin: 20px 10px;
  border-radius: 25px;
  font-weight: 600;
  font-size: 18px;
  border: 3px solid #5faef4fc;
  background: transparent;
  color: #b8c0e7;
  position: relative;
  /* animation-name: appear;
  animation-duration: 1.8s;
  animation-timing-function: ease-in; */
  animation: appear 3.5s ease-in;
  cursor: pointer;
}
@keyframes appear {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, -100px);
  }
  50% {
    opacity: 0;
    transform: translate3d(0, 0, -50px);
  }
  80% {
    opacity: 0;
    transform: translate3d(0, 0, -20px);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.explore_btn::after {
  content: "";
  height: 3px;
  width: 0;
  background: #5faef4fc;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -10px;
  transition: 0.5s;
}
.explore_btn:hover::after {
  width: 91%;
  margin-left: 4.5%;
}

@media screen and (max-width: 768px) {
  .welcome_title {
    font-size: 2.5rem;
    left: 20%;
    top: 30%;
  }
  .club_name {
    top: 42%;
  }
  .animate_character {
    font-size: 2.7rem;
  }

  .explore_btn_div {
    bottom: 20%;
  }
}

/* IEEE-title Codepen Link */
/* https://codepen.io/alvarotrigo/pen/PoKMyNO */

/* .welcome_title codepen link
https://codepen.io/alvarotrigo/pen/ExvqdNa */

/* Innovate-Imagine-Inspire codepen link
https://codepen.io/zastrow/pen/PoJmzGJ */
