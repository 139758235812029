.navbar {
  width: 100%;
  padding: 0.5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  position: fixed;
  z-index: 1000;
  max-height: 100px;
  margin-bottom: 10px;
  background: #414a4c !important;
  border-bottom: 1px solid
    var(--theme-global-nav-color-border, rgba(255, 255, 255, 0.08));
  background: var(--theme-global-nav-color-background, #1e1e1e);
}

.logo {
  display: flex; /* Aligns logo image and text horizontally */
  align-items: center; /* Vertically centers text and image */
  cursor: pointer;
  margin-left: 20px; /* Adjust this if needed */
  text-decoration: none; /* Removes underline */
}

.logo:hover {
  text-decoration: none; /* Ensures no underline on hover */
}

#logo_img {
  height: 50px; /* Adjust logo image height */
  width: auto;
  margin-right: 0; /* Remove any right margin */
}

.college-name {
  font-size: 18px;
  font-weight: bold;
  color: #fff; /* Adjust color as needed */
  text-transform: uppercase;
  margin-left: 0; /* Ensures no extra space to the left */
  padding-left: 0; /* Ensures no padding between image and text */
}

/* Other styles remain the same */
.toggle-button {
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  height: 21px;
  width: 30px;
}

.toggle-button .bar {
  height: 3px;
  width: 100%;
  background-color: #b8c0e7;
  border-radius: 10px;
}

.navbar ul li {
  list-style: none;
  display: inline-block;
  margin: 0px 30px;
  position: relative;
}

/* Dropdown and hover effects */
.navbar ul li:hover .dropdown {
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
}

.navbar ul li .dropdown {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  padding: 20px 0;
  top: 170%;
  transform: translateY(10px);
  left: 0;
  width: 200px;
  background-color: rgb(14, 25, 29);
  box-shadow: 0px 10px 10px 3px rgba(22, 44, 53, 0.3);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  z-index: 111;
  transition: 0.6s all;
}

.navbar ul li .dropdown a {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 11px;
  font-weight: 400;
  display: flex;
  color: #b8c0e7;
}

.navbar-dropdown .dropdown a:hover {
  padding-left: 18px;
  border-left: 3px solid #89cff0;
}

.navbar ul li::after {
  content: "";
  height: 3px;
  width: 0;
  background: #89cff0;
  position: absolute;
  left: 0%;
  right: 0;
  bottom: -10px;
  transition: 0.5s;
}

.navbar ul li:hover:after {
  width: 100%;
}

.navbar ul li a {
  text-decoration: none;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
}

.dropdown a span {
  margin-left: 2%;
}

.dropdown img {
  box-sizing: border-box;
  display: inline-block;
  height: 65%;
  width: 15%;
  margin-top: 0%;
  margin-bottom: 0%;
  margin-left: 5%;
  margin-right: 5%;
}
