
.slider_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  /* height: 100vh;
  width: 100vw; */
  overflow: hidden;
  position: relative;
  /* background-color: #000; */

}

.scroll-parent {
  position: relative;
  width: 100vw;
  height: 10rem;
  padding:  0;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  bottom: 0%;
}

.scroll-element {
  width: inherit;
  height: inherit;
  position: absolute;
  left: 0%;
  top: 0%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.scroll-element img {
margin: 0 14%;
  width: 400px;
  height: 150px;
  object-fit: cover;
}

.primary {
    display: flex;
    flex-direction: row;
    
    animation: primary 10s linear infinite;
}

.secondary {
    left: 10%;
    display: flex;
    flex-direction: row;
  animation: secondary 10s linear infinite;
}

@keyframes primary {
  from {
    left: 0%;
  }
  to {
    left: -100%;
  }
}

@keyframes secondary {
  from {
    left: 100%;
  }
  to {
    left: 0%;
  }
}

@media screen and (max-width: 750px ) {
  .scroll-element img {
  margin: 0 19%;
  width: 105px;
  height:90PX;
  object-fit: cover;
}
}