.course-details-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.course-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.course-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
}

.course-content {
  text-align: left;
}

.course-content h2 {
  margin-top: 20px;
  color: #333;
}

.course-content p,
.course-content ul {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
}

.course-content ul {
  padding-left: 20px;
}

.course-content ul li {
  margin-bottom: 10px;
}
.hr-1 {
  border: 0;
  height: 1px;
  margin-bottom: 5%;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(113, 227, 242, 0.75),
    rgba(0, 0, 0, 0)
  );
}
.inside_box {
  /* height: 400px; */
  /* width: 1000px; */
  width: 70%;
  max-width: 80%;
  background-color: #414a4c;
  border-radius: 20px;
}

.course-details-wrapper {
  display: flex;
}

.sidenav {
  width: 200px;
  position: sticky;
  top: 20px;
  background-color: #414a4c;
  padding: 20px;
  border-radius: 14px;
  margin-right: 20px;
}

.sidenav a {
  padding: 10px 15px;
  text-decoration: none;
  font-size: 18px;
  color: #ffffff;
  display: block;
  margin-bottom: 10px;
  border-radius: 8px;
}

.sidenav a:hover {
  background-color: #575e63;
}

.course-details-container {
  flex: 1;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 14px;
}

.course-header {
  text-align: center;
}

.course-content h2 {
  margin-top: 30px;
}

.course-content ul {
  padding-left: 20px;
  list-style-type: disc;
}

.course-content p,
.course-content ul li {
  font-size: 1.1rem;
  line-height: 1.6;
}
