#new_event_2 {
  margin-top: 0%;
}

.new_event {
  height: 400px;
  width: 90%;

  max-width: 1200px;

  /* display: grid;
  grid-template-columns: repeat(2,1fr); */

  display: flex;
  /* position: relative; */

  margin: 5% auto;

  /* justify-content: space-around; */

  border-radius: 20px;
  background-color: #414a4c;
}

.new_event img {
  height: 380px;
  /* width: 350px; */
  width: 30%;
  max-width: 50%;
  /* margin: auto; */
  padding: 0.3rem;
  /* padding-left: 1%; */
  /* position: absolute; */
  border: 5px solid 89cff0;
  border-radius: 20px;

  margin-top: auto;
  margin-bottom: auto;

  /* background-color: red; */
}

.inside_box {
  /* height: 400px; */
  /* width: 1000px; */
  width: 70%;
  max-width: 80%;
  background-color: #414a4c;
  border-radius: 20px;
}
.inside_box p {
  /* height: 90%; */
  width: 90%;
  max-height: 55%;
  overflow: auto;
  margin-left: 3%;
  padding: 1.2rem;
  color: white;
  /* font-weight: 600; */
  font-family: "Barlow", sans-serif;
  border: 1px solid #b8c0e7;
  border-radius: 10px;
}
.inside_box h1 {
  margin-bottom: 1%;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  color: 89cff0;
}
.inside_box .btn {
  background-color: 89cff0;
  width: 20%;
  height: 10%;
  margin-top: 2%;
  animation: none;
  border-radius: 15px;
}

/* */

#image1 {
  visibility: none;
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;
  border: white;
}

@media all and (max-width: 768px) {
  .new_event {
    flex-direction: column;
    height: auto;

    margin: 10% auto;
  }
  #new_event_1 .inside_box {
    height: 480px;
  }
  #new_event_2 .inside_box {
    height: 450px;
  }
  .inside_box {
    width: 90%;
    margin-right: 5%;
    max-width: 100%;
    margin: 2% auto;
  }
  .new_event img {
    height: 45vh;
    /* width: 350px; */
    width: 90%;
    max-width: 90%;
    margin: auto;
    /* position: absolute; */

    /* background-color: red; */
  }

  .inside_box h1 {
    margin-bottom: 0.79rem;
    text-align: center;
  }

  .inside_box p {
    margin-left: -2%;
  }
  #new_event_1 .inside_box .btn {
    /* background-color: #0558be; */
    width: 45%;
    height: 12%;
    font-size: 1rem;
    margin: 10% auto;
    animation: none;
    border-radius: 15px;
  }
  #new_event_2 .inside_box .btn {
    /* background-color: #0558be; */
    width: 45%;
    height: 10%;
    font-size: 1rem;
    margin-top: 15%;
    animation: none;
    border-radius: 15px;
  }

  #new_event_2 {
    margin-top: 21%;
    margin-bottom: 21%;
  }

  #image1 {
    visibility: visible;
    height: 45vh;
    /* width: 350px; */
    width: 90%;
    max-width: 90%;
    margin: auto;
    border: 5px solid #0794f1;
    border-radius: 20px;
    padding: 0.3rem;
  }
  #image2 {
    visibility: none;
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
    /* border: 0px solid white; */
  }
}
