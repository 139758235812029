body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100%;
  height: 100vh;
}

::-webkit-scrollbar {
  display: none;
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
.heading {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 1%;
  font-family: "Fira Sans", sans-serif;
  color: #0095da;
  font-size: 2.5rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  text-align: center;
  position: relative;
  margin-top: 8%;
  margin-bottom: 2%;
  font-size: 3rem;
  font-weight: 600;
}

.small_heading {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 2%;
  font-family: "Fira Sans", sans-serif;
  color: #0095da;
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 0.05rem;
  text-align: center;
  position: relative;
  font-weight: 400;
}
.hr-1 {
  border: 0;
  height: 1px;
  margin-bottom: 5%;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(113, 227, 242, 0.75),
    rgba(0, 0, 0, 0)
  );
}
.paragraph {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5;
  color: #b8c0e7;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  text-align: center;
  padding: 0px 5%;
  font-family: "Montserrat", sans-serif;
}

.btn {
  width: 130px;
  /* height: auto; */
  height: 15%;
  /* max-height: auto; */
  font-family: "Anek Telugu", sans-serif;
  font-weight: 600;
  font-size: medium;
  text-transform: uppercase;
  padding: 8px;
  /* margin-bottom: 20px; */
  color: white;
  background: #4372e9;
  /* border: 1px solid #ffd60a; */
  border-radius: 10px;
  /* box-shadow: 0px 0px 10px #0077b6; */
  border: none;
  margin: 1rem auto;
}

.btn:hover,
.btn:focus,
.btn:active {
  transition: 0.5s;
  border: none;
  text-transform: uppercase;
  background: #0095da;
  color: white;
  transform: translateY(-0.2em);
  cursor: pointer;
}

@media screen and (max-width: 750px) {
  .heading {
    font-size: 2rem;
    margin-top: 20%;
    margin-bottom: 3%;
    letter-spacing: 0.05rem;
  }
  .small_heading {
    font-size: 1.4rem;
    margin-top: 15%;
    margin-bottom: 10%;
    text-decoration: underline;
    letter-spacing: 0.02rem;
  }
  .paragraph {
    font-size: 1rem;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .btn {
    font-size: small;
  }
}
