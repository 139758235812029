.help-desk-container {
  padding: 20px;
  text-align: center;
  margin-top: 50px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.card h3 {
  margin-bottom: 10px;
}

.card a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.card a:hover {
  text-decoration: underline;
}

/* Moving message style */
.moving-message {
  margin-top: 30px;
  overflow: hidden;
  white-space: nowrap;
}

.moving-message p {
  display: inline-block;
  padding-left: 0; /* Adjust to start from the screen */
  animation: move-left 20s linear infinite;
  font-size: 18px;
  color: #e74c3c;
  font-weight: bold;
}

@keyframes move-left {
  0% {
    transform: translateX(30%); /* Starts the message from within the screen */
  }
  100% {
    transform: translateX(-100%);
  }
}
